import React from 'react';

import ScrollAnimation from '../Animation/Scroll_Animation';

import styles from './About.module.css';
import Student from '../../Images/Student.png'
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

const About = () => {
  const header = () => {
    return(
      <div className={styles.imageContainer}>
        <div>
          <ScrollAnimation 
            widget={
            <div className={styles.titleOverlay}>
              <h2 className={`${styles.subtitle} px-3 pt-2 pb-3`}>About Us</h2>
              <h2 className={`${styles.titleContent} px-3`}>At TNT Trucking School,  we take immense pride in our seasoned team of instructors, each bringing a wealth of real-world knowledge to the classroom and behind-the-wheel training. Our state-of-the-art facilities and modern fleet of vehicles ensure that our students learn in an environment that mirrors the industry's latest advancements.</h2>
            </div>
            }
            direction={'fade-up'}
          />
        </div>
      </div>
    );
  }

  const topBody = () => {
    return (
      <ScrollAnimation widget={
        <div className={`${styles.midSection} w-100 d-flex justify-content-center align-items-center flex-column flex-lg-row`}>
          <div className={`${styles.content2} w-100 w-lg-50`}>
            <div className='pt-3'>
              Over the years, we've honed a curriculum that not only meets industry standards but exceeds them. We believe in producing not just drivers, but skilled professionals equipped to navigate the challenges of the road with confidence. Our success stories are a testament to the quality education and personalized attention every student receives.
            </div>
          </div>
          <div className="d-none d-lg-flex vr" id={styles.verticalDivider} />
          <div className={`${styles.content2} w-100 w-lg-50`}>
            <div className='pt-3'>
              We believe in producing not just drivers, but skilled professionals equipped to navigate the challenges of the road with confidence.
            </div>
          </div>
        </div>
      } direction={'fade-up'} />
    );
  }

  const bottomBody = () => {
    return(
      <ScrollAnimation widget={
        <div className={`${styles.background} w-100 d-flex justify-content-center align-items-center flex-column flex-lg-row py-4`}>
          <div className='d-flex flex-column align-items-center'>
            <div className={`${styles.title3} pb-4 d-flex flex-column align-items-center`}>
              Whether you're a novice eager to kickstart your career or an experienced driver seeking advanced training, TNT Trucking School is your trusted partner in the journey towards mastery. 
            </div>
          </div>
          <div className='d-none d-lg-flex flex-column align-items-center'>
            <Image src={Student} roundedCircle />
          </div>
        </div>
      } direction={'fade-up'} />
    );
  }

  return (
    <Stack className={styles.background}>
      {header()}
      {topBody()}
      {bottomBody()}
    </Stack>
  );
};

export default About;
