import React from "react";

import ScrollAnimation from "../Animation/Scroll_Animation";

import styles from './Footer.module.css';
import logo from '../../Images/logo.svg';

const Footer = () => {
  return(
    <div className={styles.imageContainer}>
      <ScrollAnimation widget={<img className={styles.logoContainer} src={logo} alt="Footer" />} direction={'fade-up'} />
    </div>
  );
}

export default Footer;