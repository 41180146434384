import React from 'react';

import ScrollAnimation from '../Animation/Scroll_Animation';

import styles from './Courses.module.css';
import Clipboard_Big from '../../Images/Clipboard_Big.png';
import Truck_Big from '../../Images/Truck_Big.png';
import Road_Big from '../../Images/Road_Big.png';
import Shifter from '../../Images/Shifter.png';
import Log_Book from '../../Images/Log_Book.png';
import Braking from '../../Images/Braking.png';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Courses = () => {
  const accentColor = 'var(--accent-color)';

  const header = () => {
    return(
      <div className={styles.imageContainer}>
        <div>
          <ScrollAnimation 
            widget={
            <div className={styles.titleOverlay}>
              <h2 className={`${styles.subtitle} px-3 pt-2 pb-0 pb-md-3`}>Courses Offered</h2>
              <h2 className={`${styles.titleContent} px-3`}>At TNT Trucking School, we provide a thorough skill set essential for success in the trucking industry. Our curriculum covers detailed pre/post-trip inspections for safety, hands-on training in backing techniques, and precise shifting lessons for smooth gear transitions. We offer both interstate and city driving experiences, ensuring our drivers are prepared for any terrain. Additionally, our logbook compliance instruction ensures our drivers exceed industry standards.</h2>
            </div>
            }
            direction={'fade-up'}
          />
        </div>
      </div>
    );
  }

  const courseLeft = (image, title, first, second, third) => {
    return (
      <ScrollAnimation widget= {
        <div className={`${styles.courseContainer} ${styles.courseLeft} d-flex justify-content-start mb-4`}>
          <div className='d-flex align-items-center pe-2'>
            <img className={styles.image} src={image} alt={title} />
          </div>
          <div className={`d-flex align-items-center`}>
            <ListGroup className={`${styles.customListGroup}`}>
              <div className={`${styles.courseTitle} p-3`}>{title}</div>
              <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {first}</ListGroup.Item>
              <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {second}</ListGroup.Item>
              <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {third}</ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      } direction={'fade-up'} />
    );
  }

  const courseRight = (image, title, first, second, third) => {
    return (
      <ScrollAnimation widget={
        <div className={`${styles.courseContainer} ${styles.courseRight} d-flex justify-content-end mb-4`}>
          <div className='d-flex align-items-center'>
            <ListGroup className={styles.customListGroup}>
              <div className={`${styles.courseTitle} p-3`}>{title}</div>
              <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {first}</ListGroup.Item>
              <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {second}</ListGroup.Item>
              <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {third}</ListGroup.Item>
            </ListGroup>
          </div>
          <div className='d-flex align-items-center'>
            <img className={styles.image} src={image} alt={title} />
          </div>
        </div>
        
      } direction={'fade-up'} />
    );
  }

  const body = () => {
    return (
      <div className={`${styles.bodyContainer} p-5`}>
        {courseLeft(
          Clipboard_Big, 
          "Pre/Post Trip Inspections", 
          'Learn comprehensive safety checks to ensure vehicle readiness', 
          'Master the art of evaluating all critical systems from engine to tail lights', 
          'Develop a keen eye for potential issues, minimizing road_Big risks'
        )}
        {courseRight(
          Truck_Big, 
          "Backing", 
          'Gain confidence in various backing maneuvers with hands-on practice', 
          'Understand the techniques for safe reverse operations in tight spaces', 
          'Acquire skills to navigate through docks and crowded lots with ease'
        )}

        {courseLeft(
          Road_Big, 
          "Interstate & City Driving", 
          'Navigate busy city streets and open interstate stretches with expert guidance', 
          'Understand the nuances of urban vs. long-haul driving dynamics', 
          'Learn to anticipate and manage road_Big hazards unique to different environments'
        )}
        {courseRight(
          Shifter, 
          "Shifting", 
          'Master the precision of manual and automatic transmission shifting', 
          'Learn optimal gear selection for fuel efficiency and engine preservation', 
          'Experience real-world scenarios to refine smooth shifting techniques'
        )}
        {courseLeft(
          Log_Book, 
          "Log Book Compliance", 
          'Become proficient in maintaining accurate logbooks as per DOT regulations', 
          'Understand the importance of Hours of Service rules to ensure compliance', 
          'Embrace technology with e-log systems to streamline reporting'
        )}
        {courseRight(
          Braking, 
          "Braking", 
          'In-depth understanding of truck_Big braking systems for optimal safety', 
          'Practice emergency stops and controlled braking in various conditions', 
          'Learn about air brake systems, maintenance, and proper usage'
        )}
      </div>
    );
  }


  return (
    <Stack className='w-100'>
      {header()}
      <div className='d-flex w-100'>
        {body()}
      </div>
      
    </Stack>
  );
};

export default Courses;