import React, { useEffect } from 'react';
import AOS from 'aos';

import 'aos/dist/aos.css';

const ScrollAnimation = ({widget, direction}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div data-aos={direction}>
      {widget}
    </div>
  );
};

export default ScrollAnimation;