import React from "react";

import ScrollAnimation from "../Animation/Scroll_Animation";
import EmailForm from "../Email_Form/Email_Form";

import styles from "./Contact.module.css"
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocationPin, faHeadset } from '@fortawesome/free-solid-svg-icons';


const Contact = () => {
  const header = () => {
    return(
      <div className={styles.imageContainer}>
        <div>
          <ScrollAnimation 
            widget={
              <div className={`${styles.titleOverlay} py-4`}>
                <h2 className={`${styles.mainTitle} px-3 pt-2 pb-3`}>We'd Love to Talk to You</h2>
              </div>
            }
            direction={'fade-up'}
          />
        </div>
      </div>
    );
  }

  const contactInfo = (iconToUse, method, contact, contact2) => {
    return (
      <ScrollAnimation widget={
        <div className={`${styles.contactMethods} d-flex justify-content-between align-items-center`}>
          <div className="me-5"><FontAwesomeIcon size="2x" icon={iconToUse} /></div>
          <div className="d-flex flex-column">
            <div className="py-2">{method}</div>
            <div className={styles.subtitle}>{contact}</div>
            <div className={`d-inline d-md-none ${styles.subtitle}`}>{contact2}</div>
          </div>
        </div>
        
      } direction={'fade-up'} />
    );
  }

  return (
    <div className={styles.body}>
      {header()}
      <Container className={styles.custContainer}>
        <Row className="py-2 d-flex justify-content-center align-items-center">
          <Col className={`${styles.contactMethodsContainer} d-flex justify-content-center`}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col className="d-flex justify-content-start align-items-center mt-2" xs={12} sm={6} md={4}>{contactInfo(faPhone, 'Call Us', '801-972-3091', '801-390-1885')}</Col>
              <Col className="d-none d-md-flex justify-content-start align-items-center mt-2" xs={12} sm={6} md={4}>{contactInfo(faHeadset, 'Call Us', '801-972-3091', '')}</Col>
              <Col className="d-flex justify-content-start align-items-center mt-2" xs={12} sm={6} md={4}>{contactInfo(faLocationPin, 'Head Quarters', '1030 S Redwood Rd, Salt Lake City, UT 84104', '')}</Col>
            </Row>
          </Col>
          <Col xs={12} className={`${styles.bottomContainer} d-flex justify-content-center`}>
          <div className={styles.workTogether}>
            <ScrollAnimation direction={'fade-up'} widget={
              <div>
                <div className={styles.title}>Let's Work Together</div>
                <div className={styles.bodyText}>
                  Don't hesitate to reach out with any questions. We're here to help you reach your goals and get on the road.
                </div>
              </div>
            }/>

            <ScrollAnimation direction={'fade-up'} widget={
              <div className="d-none d-md-flex flex-column">
                <div className={styles.title}>Hit the Road with Confidence</div>
                <div className={styles.bodyText}>
                  We’re here to answer all your questions and guide you every step of the way. Let’s get you geared up and on the path to a successful trucking career!
                </div>
              </div>
            }/>
          </div>  
            <div className={styles.email}>
            <div className={styles.emailOverlap}>
              <div className={styles.emailContainer}>
                <ScrollAnimation 
                  widget={<EmailForm className={styles.emailContainer}/>}
                  direction={'fade-up'}
                />
              </div>
            </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    
  );
}

export default Contact;

