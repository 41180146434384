import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home.js';
import About from './Components/About/About.js';
import Courses from './Components/Courses/Courses.js';
import Career from './Components/Career/Career.js';
import Contact from './Components/Contact/Contact.js';
import MainNavbar from './Components/Navbar/Navbar.js';
import Footer from './Components/Footer/Footer.js';

import './App.css';


function App() {
  return (
    <Router>
      <MainNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
