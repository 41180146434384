import React, { useState } from 'react';
import { Form, Button, Container, FloatingLabel } from 'react-bootstrap';
import emailjs from 'emailjs-com';

import styles from './Email_Form.module.css'

const EmailForm = () => {
  const [formData, setFormData] = useState({
    first: '',
    last: '',
    email: '',
    phone: '',
    zipCode: ''
  });
  const SERVICE_ID = "service_isns33b";
  const TEMPLATE_ID = "template_ew2pnxb";
  const PUBLIC_KEY = "UHBrJcyuQz7jH6yQL";

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({ first: '', last: '', email: '', message: '' });
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
      .then((result) => {
        alert('Message Sent Successfully')
      }, (error) => {
        console.log(error.text);
        alert('Something went wrong!')
      });
    e.target.reset()
  };

  return (
    <Container className={`${styles.customFormContainer} px-4 pb-4`}>
      <div className={`${styles.formTitle} my-5`}>Request More Information</div>
      <Form onSubmit={handleSubmit}>
        <FloatingLabel controlId="formName" label="First Name" className="mb-4">
          <Form.Control
            className={styles.customFormControl}
            type="text"
            name="first"
            value={formData.first}
            onChange={handleInputChange}
            placeholder="Enter your name"
          />
        </FloatingLabel>

        <FloatingLabel controlId="formName" label="Last Name" className="mb-4">
          <Form.Control
            className={styles.customFormControl}
            type="text"
            name="last"
            value={formData.last}
            onChange={handleInputChange}
            placeholder="Enter your name"
          />
        </FloatingLabel>

        <FloatingLabel controlId="formEmail" label="Email" className="mb-4">
          <Form.Control
            className={styles.customFormControl}
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
          />
        </FloatingLabel>

        <FloatingLabel controlId="formPhone" label="Phone Number" className="mb-4">
          <Form.Control
            className={styles.customFormControl}
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Enter your phone number"
          />
        </FloatingLabel>

        <FloatingLabel controlId="formZipCode" label="Zip Code" className="">
          <Form.Control
            className={styles.customFormControl}
            type="text"
            name="zipCode"
            value={formData.zipCode}
            onChange={handleInputChange}
            placeholder="Enter your zip code"
          />
        </FloatingLabel>

        <div className='d-flex justify-content-center my-5'>
          <Button className={`${styles.customBtn}`} type="submit">
            Submit
          </Button>  
        </div>
      </Form>
      <div className={`${styles.finePrint} pb-2`}>
        By clicking “Submit” you are expressly consenting to our Terms of Use and Privacy Policy, and to TNT Trucking School, its representatives, and affiliates contacting you about your inquiry, and that they may collect sensitive information about you to provide education, financing, and job placement assistance services as explained further in our Privacy Policy. All information collected will be handled and retained as explained in the Privacy Policy.
      </div>
      <div className={styles.finePrint}>
       By clicking “Submit” and providing your phone number you are also expressly consenting to Roadmaster contacting you by text message or phone (including by use of automatic telephone dialing system(s) or an artificial or prerecorded voice) at the phone number you have provided, even if the number is listed on a Do Not Call Registry. Text/SMS: Reply Stop to cancel. Reply Help for help. All calls to and from TNT Trucking School may be monitored or recorded for quality assurance and compliance purposes.
      </div>
    </Container>
  );
};

export default EmailForm;
