import React from 'react';

import ScrollAnimation from '../Animation/Scroll_Animation';

import styles from './Career.module.css';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Career = () => {
  const header = () => {
    return(
      <div className={styles.imageContainer}>
        <div>
          <ScrollAnimation 
            widget={
              <div className={styles.titleOverlay}>
                <h2 className={`${styles.subtitle} px-3 pt-2 pb-3`}>Career Services</h2>
                <h2 className={`${styles.titleContent} px-3`}>At our core, we understand the journey from training to employment is pivotal for every aspiring trucker. That's why we're committed to not just educating our graduates but also ensuring they find their footing in the trucking industry. Our Career Services offer comprehensive job placement assistance, designed to bridge the gap between certification and employment. Through personalized support, resume crafting, and access to an extensive network of industry partners, we're here to open doors for our graduates. Whether you're looking to explore long-haul routes or regional deliveries, our resources are tailored to meet your ambitions and skills. </h2>
              </div>
            }
            direction={'fade-up'}
          />
        </div>
      </div>
    );
  }

  return (
    <Stack className='w-100'>
      {header()}
      <div className={`${styles.bodyContainer} px-2 pt-4`}>
        <ScrollAnimation widget={
          <div>
            <div className={`${styles.title} py-4`}>Local Job Placement</div>
            <div className={`${styles.content1}`}>
              At TNT, we take immense pride in fostering strong local connections that pave the way for our graduates' careers right in their own backyards. Our Local Job Placement program is designed with a deep understanding of the local logistics landscape, ensuring that you don't just find a job, but you embark on a career path that feels like home. Through strategic partnerships with leading local transport and logistics companies, we offer an unrivaled network of opportunities tailored to meet the demands and preferences of our community. Our dedicated team works tirelessly to match your skills and aspirations with the perfect local position, ensuring a seamless transition from classroom to cab. Start your journey with us, and drive your future forward, one local mile at a time.
            </div>
          </div>
        } direction={'fade-up'} />

        <ScrollAnimation widget={
          <Row className={`${styles.midSection} d-flex mt-5`}>
            <Col md={6}>
            <div className={`${styles.sellingPoint}`}>
              If you're drawn to the freedom of the road and the thrill of new destinations, our OTR program is your first step towards a life of adventure and professional fulfillment.
            </div>
              
            </Col>
            <Col md={6} className='d-flex flex-column'>
              <div className={`${styles.title2} py-4`}>Over the Road Availability</div>
              <div className={`${styles.content2} pb-4`}>
                Embark on the adventure of a lifetime with our Over the Road (OTR) availability program, designed for those who dream of the open highway and distant horizons. This program is your gateway to exploring the vast landscapes of our nation, while forging a rewarding career in long-haul trucking. With access to a wide array of routes spanning coast to coast, our graduates are equipped to take on journeys that not only challenge them professionally but also enrich them personally. 
                Our partnerships with industry-leading freight and logistics companies ensure a steady stream of opportunities that promise both variety and stability. If you're drawn to the freedom of the road and the thrill of new destinations, our OTR program is your first step towards a life of adventure and professional fulfillment.
              </div>
            </Col>
          </Row>
        } direction={'fade-up'} />
        
        
      </div>  
    </Stack>
  );
};

export default Career;
