import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../Images/logo.svg';
import styles from './Navbar.module.css';

const MainNavbar = () => {
  const location = useLocation();
  return (
    <Navbar collapseOnSelect expand="lg" className={styles.customNavbar}>
      <Container id={styles.custContainer}>
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="100%"
            height="65"
            className="d-inline-block align-top"
            alt="TNT Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
          <Nav></Nav>
          <Nav className="justify-content-end">
            <Nav.Link as={Link} to="/" className={`${styles.navLink} ${location.pathname === "/" ? styles.navLinkActive : ""}`}>
              Home
            </Nav.Link>

            <Nav.Link as={Link} to="/courses" className={`${styles.navLink} ${location.pathname === "/courses" ? styles.navLinkActive : ""}`}>
                Courses Offered
            </Nav.Link>

            <Nav.Link as={Link} to="/career" className={`${styles.navLink} ${location.pathname === "/career" ? styles.navLinkActive : ""}`}>
                Career Services
            </Nav.Link>

            <Nav.Link as={Link} to="/about" className={`${styles.navLink} ${location.pathname === "/about" ? styles.navLinkActive : ""}`}>
                About Us
            </Nav.Link>
            
            <Nav.Link as={Link} to="/contact" className={`${styles.navLink} ${location.pathname === "/contact" ? styles.navLinkActive : ""}`}>
                Contact
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;