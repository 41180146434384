import React from 'react';
import { useState, useEffect } from 'react';

import ScrollAnimation from '../Animation/Scroll_Animation';
import EmailForm from '../Email_Form/Email_Form';

import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import Clipboard from '../../Images/Clipboard.png';
import Truck from '../../Images/Truck.png';
import Road from '../../Images/Road.png';
import Fade from 'react-bootstrap/Fade';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCheck } from '@fortawesome/free-solid-svg-icons';
 
const Home = () => {
  const [open, setOpen] = useState(false);

  const accentColor = 'var(--accent-color)';

  useEffect(() => {
    setOpen(!open);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = () => {
    return(
      <div className={styles.imageContainer}>
        <div>
          <ScrollAnimation 
            widget={
            <div className={styles.titleOverlay}>
              <h1 className={`${styles.title} d-flex justify-content-center align-items-center`}>TNT Trucking School</h1>
              <h2 className={`${styles.subtitle} pt-2 pb-3`}>Where Expertise Meets Experience</h2>
              <h2 className={`${styles.titleContent} px-3`}>With a proud legacy spanning two decades, we've been the driving force behind countless successful careers on the open road. Established in 2004, our commitment to excellence in truck driver education has stood the test of time. As one of the few bilingual schools in Utah, we're excited to serve you and help you confidently hit the open road.</h2>
            </div>
            }
            direction={'fade-up'}
          />
        </div>
      </div>
    );
  }

  const createCourseCard = (image, title, first, second, third) => {
    return (
      <ScrollAnimation widget={
        <Container className={styles.courseCard}>
          <Row className='d-flex flex-column justify-content-center align-items-center'>
            <Col className='d-flex justify-content-center align-items-center py-2'>
              <img src={image} alt={title} />
            </Col>
            <Col className='py-2'>
              <div className={styles.courseTitle}>{title}</div>
            </Col>
            <Col className='d-flex justify-content-center align-items-center py-2'>
              <ListGroup className={styles.customListGroup}>
                <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {first}</ListGroup.Item>
                <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {second}</ListGroup.Item>
                <ListGroup.Item className={styles.customListItem}> <span className='pe-2'><FontAwesomeIcon color={accentColor} icon={faCheck} /></span> {third}</ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
        }
        direction={'fade-down'}
      />
      
    );
  }

  const body = () => {
    return (
      <div className={styles.bodyContainer}>
        <Row className='d-flex justify-content-center align-items-center'>
          <Col xs={12} md={6} className='d-flex flex-column'>
            <div className={`${styles.bodyTitle} pb-3 pt-5`}>Courses Offered</div>
            <div className='d-flex flex-column justify-content-center align-items-center py-3'>
              {createCourseCard(
                Clipboard, 
                "Pre/Post Trip Inspections", 
                'Learn comprehensive safety checks to ensure vehicle readiness', 
                'Master the art of evaluating all critical systems from engine to tail lights', 
                'Develop a keen eye for potential issues, minimizing road risks'
              )}
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center py-3'>
              {createCourseCard(
                Truck, 
                "Backing", 
                'Gain confidence in various backing maneuvers with hands-on practice', 
                'Understand the techniques for safe reverse operations in tight spaces', 
                'Acquire skills to navigate through docks and crowded lots with ease'
              )}
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center py-3'>
              {createCourseCard(
                Road, 
                "Interstate & City Driving", 
                'Navigate busy city streets and open interstate stretches with expert guidance', 
                'Understand the nuances of urban vs. long-haul driving dynamics', 
                'Learn to anticipate and manage road hazards unique to different environments'
              )}
            </div>
            <Link to="/courses" className={`${styles.courseLink} my-5`}>View All Courses Here</Link>
          </Col>
          <Col xs={12} md={6} className='my-3'>
            <div className={styles.emailOverlap}>
              <div className={styles.emailContainer}>
                <ScrollAnimation 
                  widget={<EmailForm className={styles.emailContainer}/>}
                  direction={'fade-up'}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Stack className='w-100'>
      <Fade in={open}>
       {header()}
      </Fade>
      {body()}
    </Stack>
  );
};

export default Home;
